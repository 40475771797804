import React, { useEffect, createRef } from "react"

import { Box } from "@chakra-ui/layout"
import animation from "./contact-us.json"
import lottie from "lottie-web"
import "./Animation.css"

const ContactAnimation = () => {
  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => anim.destroy()
  }, [animationContainer])
  return (
    <Box
      className="animation"
      width={["320px", "420px", "100%", "100%"]}
      margin="0 auto"
      ref={animationContainer}
    />
  )
}

export default ContactAnimation
