import React from "react"
import ContactComp from "../components/Contact/ContactComp"
import logo from "../images/sensetechLogo.png"
import { Helmet } from "react-helmet"

const contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SenseTech: Contact</title>
        <meta
          name="description"
          content="SenseTech : App Development || Cloud Support || Migration to Micro Frontend || Cloud Migration support || RPA || Chatbot || E-commerce store"
        />
        <meta
          name="keywords"
          content="mobile app development , web app development , software company , IT solution, IT services"
        />
        <meta name="author" content="SenseTech Innovations Private Limited" />
        <meta property="og:image" content={logo} />
        <link rel="canonical" href="https://thesensetech.com" />
      </Helmet>
      <ContactComp />
    </>
  )
}

export default contact
