import React, { useState } from "react"
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input"
import { Stack, Text } from "@chakra-ui/layout"
import { faCaretDown, faPhone } from "@fortawesome/free-solid-svg-icons"
import {
  faEnvelopeOpen,
  faPaperPlane,
  faUser,
} from "@fortawesome/free-regular-svg-icons"
import { Button, Select, Textarea } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import swal from "sweetalert"
import firebase from "../../firebase"
import "./ContactComp.css"

const initialState = {
  name: "",
  email: "",
  phone: "",
  service: "",
  message: "",
}
const ContactForm = () => {
  const [formData, setFormData] = useState(initialState)
  const [inputData, setInputData] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [validPhone, setValidPhone] = useState(false)

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  function validatePhone(phone) {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    return re.test(phone)
  }

  // Function for email validation
  const emailValidation = () => {
    if (formData.name === "" || formData.email === "") {
      setInputData(true)
      setTimeout(() => {
        setInputData(false)
      }, 2000)
    } else {
      if (validateEmail(formData.email) || validatePhone(formData.phone)) {
        firebase
          .firestore()
          .collection("leads")
          .add(formData)
          .then(ref => {
            setFormData(initialState)
          })
        swal("Great Work!", "We'll contact you very soon", "success")
        setFormData(initialState)
      } else {
        setValidEmail(true)
        setTimeout(() => {
          setValidEmail(false)
        }, 2000)
      }
    }
  }

  const handleSubmitData = () => {
    if (formData.phone !== "") {
      if (validatePhone(formData.phone)) {
        emailValidation()
      } else {
        setValidPhone(true)
        setTimeout(() => {
          setValidPhone(false)
        }, 2000)
      }
    } else {
      emailValidation()
    }
  }

  return (
    <Stack
      bg="#ffffff"
      p="25px"
      boxShadow="0 0 10px rgba(0,0,0,0.3)"
      borderRadius="7px"
      spacing={3}
      margin="0 auto"
      width={["300px", "380px", "60%", "60%"]}
    >
      <InputGroup width="100%" mb="0" pb="0">
        <InputLeftElement
          pointerEvents="none"
          children={<FontAwesomeIcon icon={faUser} />}
        />
        <Input
          onChange={e => setFormData({ ...formData, name: e.target.value })}
          value={formData.name}
          type="name"
          placeholder="Your Name"
          className={inputData ? "valid_form" : ""}
        />
      </InputGroup>
      {inputData && (
        <Text color="red" mb="14px" mt="0 !important">
          This field is required
        </Text>
      )}

      <InputGroup width="100%">
        <InputLeftElement
          pointerEvents="none"
          children={<FontAwesomeIcon icon={faEnvelopeOpen} />}
        />
        <Input
          onChange={e => setFormData({ ...formData, email: e.target.value })}
          value={formData.email}
          type="email"
          placeholder="Email Address"
          className={inputData ? "valid_form" : ""}
        />
      </InputGroup>
      {inputData && (
        <Text color="red" mb="14px" mt="0 !important">
          This field is required
        </Text>
      )}
      {validEmail && (
        <Text color="red" mb="14px" mt="0 !important">
          Please enter a valid email
        </Text>
      )}

      <InputGroup width="100%">
        <InputLeftElement
          pointerEvents="none"
          children={<FontAwesomeIcon icon={faPhone} />}
        />
        <Input
          onChange={e => setFormData({ ...formData, phone: e.target.value })}
          value={formData.phone}
          type="email"
          placeholder="Phone Number (optional)"
        />
      </InputGroup>
      {validPhone && (
        <Text color="red" mb="14px" mt="0 !important">
          Please enter a valid phone number
        </Text>
      )}

      <Select
        width="100%"
        icon={<FontAwesomeIcon icon={faCaretDown} />}
        placeholder="Select a service"
        onChange={e => setFormData({ ...formData, service: e.target.value })}
        value={formData.service}
      >
        <option value="RPA">RPA</option>
        <option value="Mobile application">Mobile Application</option>
        <option value="Web Application">Web Application</option>
        <option value="Cloud Support">Cloud support</option>
        <option value="CRM Integration">CRM Integration</option>
        <option value="Migration to microfrontend">
          Migration to microfrontend
        </option>
        <option value="Chatbot">Chatbot</option>
        <option value="E-commerce store setup">E-commerce store setup</option>
        <option value="Others">Others</option>
      </Select>

      <InputGroup width="100%">
        <Textarea
          onChange={e => setFormData({ ...formData, message: e.target.value })}
          value={formData.message}
          placeholder="Enter Your Message"
        />
      </InputGroup>

      <InputGroup width="100%">
        <Button
          rightIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          bg="#02073e"
          colorScheme="#02073e"
          variant="solid"
          width="100%"
          onClick={handleSubmitData}
        >
          Send
        </Button>
      </InputGroup>
    </Stack>
  )
}

export default ContactForm
