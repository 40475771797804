import { Box } from "@chakra-ui/layout"

import React from "react"
import { Image } from "@chakra-ui/react"

import whatsapp from "../../images/whatsapp.png"
import ContactAnimation from "../../animations/ContactAnimation"
import Navbar from "../Utilities/Navbar/Navbar"
import "./ContactComp.css"
import ContactForm from "./ContactForm"
import FooterCommon from "../Utilities/FooterCommon/FooterCommon"
import { Link } from "gatsby"

const ContactComp = () => {
  return (
    <>
      <Navbar />
      <Box className="contactComp">
        <Box
          position="relative"
          p="10px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          pt="100px"
        >
          <Box
            width={["100%", "100%", "70%", "70%"]}
            margin="0 auto"
            display={["block", "block", "flex", "flex"]}
            alignItems="center"
            justifyContent="center"
          >
            <ContactForm />
            <ContactAnimation />
          </Box>
          <Link to="https://wa.me/+919896740648" target="_blank">
            <Image
              position="fixed"
              right={["10px", "16px", "18px", "18px"]}
              bottom={["100px", "100px", "120px", "120px"]}
              w={["60px", "70px", "80px", "80px"]}
              src={whatsapp}
              cursor="pointer"
            />
          </Link>
        </Box>
      </Box>
      <FooterCommon />
    </>
  )
}

export default ContactComp
